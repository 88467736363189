import React from 'react';

const ModalLang = ({ hideModalLang, toggleModalLang, children }) => {
    if (hideModalLang) return null;

    return [
        <div className="modalOverlay" onClick={() => toggleModalLang()} />,
        <div className="modalWrap">
            <div className="modalLang">
                {children}
            </div>
        </div>
    ];
}

export default ModalLang;