import React, { useState } from "react";
import { Link } from "react-router-dom";

// CALENDAR
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";

// COMPONENTS
import ModalLang from "../Components/ModalLang";
import Weather from "../Components/Weather";

// ASSETS
import { FaFacebookSquare, FaInstagram, FaStar } from "react-icons/fa";
import { HiTranslate } from "react-icons/hi";

import freeWiFi from "../Assets/utilityImages/freewifi.png";
import hotBeverage from "../Assets/utilityImages/tea.png";
import freeParking from "../Assets/utilityImages/freeParking.png";
import sun from "../Assets/sun.png";
import hairDryer from "../Assets/utilityImages/hairdryer.png";
import shower from "../Assets/utilityImages/shower.png";
import petFriendly from "../Assets/utilityImages/petfriendly.png";
import iron from "../Assets/utilityImages/iron.png";
import tv from "../Assets/utilityImages/smarttv.png";
import bed from "../Assets/utilityImages/doublebed.png";
import aircon from "../Assets/utilityImages/aircon.png";
import feedbacks from "../Assets/feedbacks.png";
import security from "../Assets/utilityImages/security.png";
import clean from "../Assets/utilityImages/clean.png";

import img1 from "../Assets/villa/IMG1.jpg";
import img2 from "../Assets/villa/IMG2.jpg";
import img3 from "../Assets/villa/IMG3.jpg";
import img4 from "../Assets/villa/IMG4.jpg";
import img5 from "../Assets/villa/IMG5.jpg";
import img6 from "../Assets/villa/IMG6.jpg";
import img7 from "../Assets/villa/IMG7.jpg";
import img8 from "../Assets/villa/IMG8.jpg";
import img9 from "../Assets/villa/IMG9.jpg";
import img10 from "../Assets/villa/IMG10.jpg";
import img11 from "../Assets/villa/IMG11.jpg";
import img12 from "../Assets/villa/IMG12.jpg";
import img13 from "../Assets/villa/IMG13.jpg";
import img14 from "../Assets/villa/IMG14.jpg";

import calendar from "../Assets/calendar.png";
import map from "../Assets/map.png";
import love from "../Assets/love.png";
import phone from "../Assets/phone.png";
import email from "../Assets/email.png";

// SWIPER
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Pagination, EffectCoverflow } from "swiper";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";

function Homepage() {
  const [hideModalLang, setHideModalLang] = useState(true);
  const toggleModalLang = () => setHideModalLang(!hideModalLang);

  const configModal = {
    hideModalLang,
    toggleModalLang,
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);

  const [count, setCount] = useState(1);
  const [count2, setCount2] = useState(1);

  const date1 = startDate;
  const date2 = endDate;
  const diffTime = Math.abs(date2 - date1);

  // const rooms = count2;
  // const diffRooms = Math.abs(diffTime / (4 * 60 * 60 * 24)) * rooms;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  const handleIncrement = () => {
    setCount((prevCount) => prevCount + 1);
  };
  const handleDecrement = () => {
    setCount((prevCount) => prevCount - 1);
  };
  const handleIncrement2 = () => {
    setCount2((prevCount) => prevCount + 1);
  };
  const handleDecrement2 = () => {
    setCount2((prevCount) => prevCount - 1);
  };

  const handleSubmit = (e) => {
    const url =
      "https://wa.me/40720787274?text=" +
      "Buna ziua, as dori o camera pentru data de: " +
      startDate.format("DD MMM YYYY") +
      " - " +
      endDate.format("DD MMM YYYY") +
      "(" +
      diffDays +
      "nopti)" +
      "%0a" +
      "Rooms: " +
      count2 +
      "%0a" +
      "Guests: " +
      count;

    window.open(url, "_blank").focus();
  };

  return (
    <div className="homepage">
      <div className="fixedBackground" />
      <div className="fixedBottomBackground" />
      <div className="homepageCenter">
        <div>
          <div className="homepageLogo">
            <div className="homepageWeather">
              <Weather />
            </div>
            <HiTranslate onClick={() => toggleModalLang()} />
            <div className="homepageLogoText">
              <h1 className="homepageHeader">HYPNOTIQUE</h1>
              <h4>Mamaia Nord</h4>
            </div>
          </div>
          <form onSubmit={handleSubmit} className="bookingForm">
            <br />
            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path fill="#fff" fill-opacity="1" d="M0,128L80,112C160,96,320,64,480,80C640,96,800,160,960,181.3C1120,203,1280,181,1360,170.7L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
                        </svg> */}
            <div className="homepageHeaderOverlay">
              <DateRangePicker
                startDate={startDate}
                startDateId="s_id"
                endDate={endDate}
                endDateId="e_id"
                onDatesChange={({ startDate, endDate }) => {
                  setStartDate(startDate);
                  setEndDate(endDate);
                }}
                focusedInput={focusedInput}
                onFocusChange={(e) => setFocusedInput(e)}
                displayFormat="DD-MMM-YYYY"
                numberOfMonths="1"
                withPortal="true"
                id="dates"
                required
              />

              <div className="bookingInfo" id="bookingDetails">
                <div className="flexDivTop">
                  <label htmlFor="s_id">
                    <strong>Check-in:</strong>{" "}
                    <span className="capitalize">
                      <strong>
                        <small>{startDate && startDate.format("ddd")}</small>
                      </strong>
                    </span>{" "}
                    <strong>
                      <small>
                        {startDate && startDate.format("DD MMM YYYY")}
                      </small>
                    </strong>
                  </label>
                  <br />
                  <label htmlFor="e_id">
                    <strong>Check-out:</strong>{" "}
                    <span className="capitalize">
                      <strong>
                        <small>{endDate && endDate.format("ddd")}</small>
                      </strong>
                    </span>{" "}
                    <strong>
                      <small>{endDate && endDate.format("DD MMM YYYY")}</small>
                    </strong>
                  </label>
                </div>
                <br />
                <div className="flexDiv">
                  <div className="counter">
                    <label>Guests</label>
                    <div className="counterHandler">
                      <button type="button" onClick={handleDecrement}>
                        -
                      </button>
                      <p>{count}</p>
                      <button type="button" onClick={handleIncrement}>
                        +
                      </button>
                    </div>
                  </div>
                  <div className="counter">
                    <label>Rooms</label>
                    <div className="counterHandler">
                      <button type="button" onClick={handleDecrement2}>
                        -
                      </button>
                      <p>{count2}</p>
                      <button type="button" onClick={handleIncrement2}>
                        +
                      </button>
                    </div>
                  </div>
                </div>
                <button className="whiteButton" onSubmit={handleSubmit}>
                  Request reservation
                </button>
                <button
                  className="transparentButton"
                  onClick={() => setStartDate() && setEndDate()}
                >
                  Cancel reservation
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="homepageTop">
        <br />
        <br />
        <br />
        <h2>Price details</h2>
        <br />
        <div className="swiperWrapper">
          <Swiper
            autoHeight={true}
            centeredSlides={true}
            modules={[Pagination, EffectCoverflow]}
            slidesPerView={3}
            onSwiper={(swiper) => console.log(swiper)}
            loop={true}
            effect={"coverflow"}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
          >
            <SwiperSlide>
              <div className="priceCard">
                <br />
                <img
                  src={calendar}
                  alt="Hypnotique Villa"
                  className="priceCalendar"
                />

                <h3>April</h3>
                <h5>Week rates: 250 RON per night</h5>
                <h5>Fri/Sat: 300 RON per night</h5>
                <br />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="priceCard">
                <br />
                <img
                  src={calendar}
                  alt="Hypnotique Villa"
                  className="priceCalendar"
                />

                <h3>May</h3>
                <h5>Week rates: 300 RON per night</h5>
                <h5>Fri/Sat: 400 RON per night</h5>
                <br />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="priceCard">
                <br />
                <img
                  src={calendar}
                  alt="Hypnotique Villa"
                  className="priceCalendar"
                />

                <h3>June</h3>
                <h5>Week rates: 350 RON per night</h5>
                <h5>Fri/Sat: 400 RON per night</h5>
                <br />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="priceCard">
                <br />
                <img
                  src={calendar}
                  alt="Hypnotique Villa"
                  className="priceCalendar"
                />

                <h3>July</h3>
                <h5>Week rates: 400 RON per night</h5>
                <h5>Fri/Sat: 450 RON per night</h5>
                <br />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="priceCard">
                <br />
                <img
                  src={calendar}
                  alt="Hypnotique Villa"
                  className="priceCalendar"
                />
                <h3>August</h3>
                <h5>Week rates: 450 RON per night</h5>
                <h5>Fri/Sat: 500 RON per night</h5>
                <br />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="priceCard">
                <br />
                <img
                  src={calendar}
                  alt="Hypnotique Villa"
                  className="priceCalendar"
                />
                <h3>September</h3>
                <h5>Week rates: 300 RON per night</h5>
                <h5>Fri/Sat: 350 RON per night</h5>
                <br />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="priceCard">
                <br />
                <img
                  src={calendar}
                  alt="Hypnotique Villa"
                  className="priceCalendar"
                />
                <h3>October</h3>
                <h5>Week rates: 250 RON per night</h5>
                <h5>Fri/Sat: 300 RON per night</h5>
                <br />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="priceCard">
                <br />
                <img
                  src={calendar}
                  alt="Hypnotique Villa"
                  className="priceCalendar"
                />
                <h3>November</h3>
                <h5>Week rates: 250 RON per night</h5>
                <h5>Fri/Sat: 300 RON per night</h5>
                <br />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="priceCard">
                <br />
                <img
                  src={calendar}
                  alt="Hypnotique Villa"
                  className="priceCalendar"
                />
                <h3>December</h3>
                <h5>Week rates: 250 RON per night</h5>
                <h5>Fri/Sat: 300 RON per night</h5>
                <br />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <br />
        <br />
        <br />
        <h2>Photo gallery</h2>
        <br />
        <div className="gallery">
          <div className="galleryScroll">
            <div className="galleryScrollContent">
              <img src={img14} alt="Hypnotique Villa" />
            </div>
            <div className="galleryScrollContent">
              <img src={img10} alt="Hypnotique Villa" />
            </div>
            <div className="galleryScrollContent">
              <img src={img1} alt="Hypnotique Villa" />
            </div>
            <div className="galleryScrollContent">
              <img src={img2} alt="Hypnotique Villa" />
            </div>
            <div className="galleryScrollContent">
              <img src={img3} alt="Hypnotique Villa" />
            </div>
            <div className="galleryScrollContent">
              <img src={img4} alt="Hypnotique Villa" />
            </div>
            <div className="galleryScrollContent">
              <img src={img5} alt="Hypnotique Villa" />
            </div>
            <div className="galleryScrollContent">
              <img src={img6} alt="Hypnotique Villa" />
            </div>
            <div className="galleryScrollContent">
              <img src={img7} alt="Hypnotique Villa" />
            </div>
            <div className="galleryScrollContent">
              <img src={img8} alt="Hypnotique Villa" />
            </div>
            <div className="galleryScrollContent">
              <img src={img9} alt="Hypnotique Villa" />
            </div>

            <div className="galleryScrollContent">
              <img src={img11} alt="Hypnotique Villa" />
            </div>
            <div className="galleryScrollContent">
              <img src={img12} alt="Hypnotique Villa" />
            </div>
            <div className="galleryScrollContent">
              <img src={img13} alt="Hypnotique Villa" />
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <h2>Our features</h2>
        <br />
        <div className="utilityBox">
          <div className="utilityIcons">
            <img src={freeWiFi} alt="Hypnotique Villa" />
            <p>Free WiFi</p>
          </div>
          <div className="utilityIcons">
            <img src={hotBeverage} alt="Hypnotique Villa" />
            <p>Beverages</p>
          </div>
          <div className="utilityIcons">
            <img src={freeParking} alt="Hypnotique Parking" />
            <p>Free parking</p>
          </div>
          <div className="utilityIcons">
            <img src={hairDryer} alt="Hypnotique Villa" />
            <p>Hair dryer</p>
          </div>
          <div className="utilityIcons">
            <img src={security} alt="Hypnotique Villa" />
            <p>Security 24/7</p>
          </div>
          <div className="utilityIcons">
            <img src={clean} alt="Hypnotique Villa" />
            <p>Daily cleaning</p>
          </div>
          <div className="utilityIcons">
            <img src={shower} alt="Hypnotique Villa" />
            <p>Shower & body gel</p>
          </div>
          <div className="utilityIcons">
            <img src={petFriendly} alt="Hypnotique Villa" />
            <p>Pets friendly</p>
          </div>
          <div className="utilityIcons">
            <img src={aircon} alt="Hypnotique Villa" />
            <p>Air conditioner</p>
          </div>
          <div className="utilityIcons">
            <img src={iron} alt="Hypnotique Villa" />
            <p>Clothes iron</p>
          </div>
          <div className="utilityIcons">
            <img src={tv} alt="Hypnotique Villa" />
            <p>Smart TV</p>
          </div>
          <div className="utilityIcons">
            <img src={bed} alt="Hypnotique Villa" />
            <p>Kingsize bed</p>
          </div>
        </div>
        <br />
        <br />
        <br />

        <h2>Nearby places</h2>
        <br />
        <div className="utilityBox">
          <div className="nearbyBox">
            <br />
            <h5>Popular</h5>
            <p>
              Mackerel <span>300 m</span>
            </p>
            <p>
              Siutghiol Lake <span>1.6 km</span>
            </p>
            <p>
              Aqua Magic <span>8.5 km</span>
            </p>
            <p>
              Telegondola Mamaia <span>8.8 km</span>
            </p>
            <br />
          </div>
          <div className="nearbyBox">
            <br />
            <h5>Top attractions</h5>
            <p>
              City Park Mall <span>10.6 km</span>
            </p>
            <p>
              Tomis Yachting Club and Marina <span>14 km</span>
            </p>
            <p>
              National History Museum <span>14.2 km</span>
            </p>
            <p>
              Constanta Casino <span>14.6 km</span>
            </p>
            <br />
          </div>
          <div className="nearbyBox">
            <br />
            <h5>Restaurant & cafes</h5>
            <p>
              Cafe/Bar - Kazeboo <span>0.8 km</span>
            </p>
            <p>
              Restaurant - Oneiro Resort <span>0.9 km</span>
            </p>
            <p>
              Restaurant - Nazar Turkish <span>1.1 km</span>
            </p>
            <p>
              Restaurant - Nikos Greek <span>1.2 km</span>
            </p>
            <p>
              Restaurant - La Scoica <span>1.9 km</span>
            </p>
            <br />
          </div>
          <div className="nearbyBox">
            <br />
            <h5>Nighlife</h5>
            <p>
              LOFT <span>3.6 km</span>
            </p>
            <p>
              Fratelli <span>3.6 km</span>
            </p>
            <p>
              Crazy beach <span>3.7 km</span>
            </p>
            <p>
              Bamboo <span>3.7 km</span>
            </p>
            <p>
              Asylum <span>3.7 km</span>
            </p>
            <br />
          </div>
          <div className="nearbyBox">
            <br />
            <h5>Beaches</h5>
            <p>
              Mamaia Beach <span>500 m</span>
            </p>
            <p>
              Marina Regia <span>1.3 km</span>
            </p>
            <p>
              Phoenicia Beach <span>2 km</span>
            </p>
            <br />
          </div>
          <div className="nearbyBox">
            <br />
            <h5>Transport</h5>
            <p>
              Public bus to Constanta - No. 23 <span>20 m</span>
            </p>
            <p>
              Ferry Ovidiu Island Ferry Wharf <span>6.5 km</span>
            </p>
            <p>
              Mihail Kogălniceanu Airport <span>12.7 km</span>
            </p>
            <br />
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <img className="subheaderImg" src={feedbacks} alt="Hypnotique Villa" />
        <h2>Feedbacks</h2>
        <br />
        <p className="feedbackParagraph">
          <strong>Exceptional</strong> - 9.5 <FaStar /> on{" "}
          <strong>Booking.com</strong>
        </p>
        <div className="feedbackRow">
          <div className="feedbackFlex">
            <div className="feedbackCard">
              <p>
                “The owner was very friendly and kind and he made us feel like
                home :)”
                <strong>
                  Robert - <span>Romania</span>
                </strong>
              </p>
            </div>
            <div className="feedbackCard">
              <p>
                “The staff was nice and helpful, the room looked exactly like in
                the pictures and the location was ok, easy to go to Mamaia Nord
                by taxi, or straight to the Navodari Beach by foot. Clean,
                modern and comfortable. Good value for money.”
                <strong>
                  Diana - <span>Romania</span>
                </strong>
              </p>
            </div>
            <div className="feedbackCard">
              <p>
                “It was the best accomodation I have ever visited on The Black
                Sea coast. The owner gave us the discount because one day the
                weather was not as good as we expected.”
                <strong>
                  Irgor - <span>Poland</span>
                </strong>
              </p>
            </div>
            <div className="feedbackCard">
              <p>
                “The owner was friendly and super helpful when needed. He offer
                us a prossecco bottle complimentary.”
                <strong>
                  Dana - <span>Romania</span>
                </strong>
              </p>
            </div>
            <div className="feedbackCard">
              <p>
                “The property is new, with parking for cars, with all the
                facilities. The staff is very nice people. The rooms are big,
                clean and dotated with all neccessary.”
                <strong>
                  Anonymous - <span>Romania</span>
                </strong>
              </p>
            </div>
            <div className="feedbackCard">
              <p>
                “I still liked it, the mattress was very soft, everything is
                clean and elegant, we always come here and every time we feel
                super well ❤️❤️❤️❤️”
                <strong>
                  Olteanu - <span>Romania</span>
                </strong>
              </p>
            </div>
            <div className="feedbackCard">
              <p>
                “The property is relatively new, clean, about 5 minutes walk
                from the beach. The owner is kind and willing to answer
                questions.”
                <strong>
                  Bianca - <span>Romania</span>
                </strong>
              </p>
            </div>
            <div className="feedbackCard">
              <p>
                “Friendly staff, cleanliness, tranquility, in a word the best
                conditions to spend an excellent holiday by the sea !!!”
                <strong>
                  Madalina - <span>Romania</span>
                </strong>
              </p>
            </div>
            <div className="feedbackCard">
              <p>
                “Very good reception, very well located near the beach. I highly
                recommend, I will definitely return. ”
                <strong>
                  Luc - <span>France</span>
                </strong>
              </p>
            </div>
            <div className="feedbackCard">
              <p>
                “Cleaning I came with a baby that currently goes more than the
                bush, I could leave it on the carpet without problems The design
                of the rooms is nice”
                <strong>
                  Ana - <span>Romania</span>
                </strong>
              </p>
            </div>
            <div className="feedbackCardLast">
              <h3>View more</h3>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <h2>Contact us</h2>
        <br />
        <div className="contactDetails">
          <div className="contactBox">
            <a href="tel:+40720787274">
              <p>
                <strong>
                  <img src={phone} alt="Hypnotique Villa" />
                  <br />
                  Phone:
                </strong>
              </p>
              <br />
              <p>+40720787274</p>
            </a>
          </div>
          <div className="contactBox">
            <a href="https://goo.gl/maps/movQLqnCuTM98FfL8">
              <p>
                <strong>
                  <img src={map} alt="Hypnotique Villa" />
                  <br />
                  Maps:
                </strong>
              </p>
              <br />
              <p>Str. D21, Nr. 8, Mamaia Nord, Romania</p>
            </a>
          </div>
          <div className="contactBox">
            <a href="mailto:villa.hypnotique@gmail.com">
              <p>
                <strong>
                  <img src={email} alt="Hypnotique Villa" />
                  <br />
                  Email:
                </strong>
              </p>
              <br />
              <p>
                villa.hypnotique
                <br />
                @gmail.com
              </p>
            </a>
          </div>
          <div className="contactBox">
            <p>
              <strong>
                <img src={love} alt="Hypnotique Villa" />
                <br />
                Social:
              </strong>
            </p>
            <br />
            <a href="https://www.instagram.com/hypnotique.villa/">
              <p className="flexParagraph">
                <FaInstagram /> hypnotique.villa
              </p>
            </a>
            <a href="https://www.facebook.com/hypnotiquevilla">
              <p className="flexParagraph">
                <FaFacebookSquare /> /hypnotiquevilla
              </p>
            </a>
          </div>
        </div>
        <br />
        <br />
        <br />
        <a
          href="https://husnudinislam.web.app"
          className="footerLink"
          rel="noreferrer"
          target="_blank"
        >
          Designed with <span>&#x2764;</span> by <strong>husnudinislam</strong>
        </a>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
      <ModalLang {...configModal}>
        <div>
          <Link to="/">
            <p>English</p>
          </Link>
          <Link to="/ro">
            <p>Romanian</p>
          </Link>
        </div>
      </ModalLang>
    </div>
  );
}

export default Homepage;
