import React from "react";
import { Switch, Route } from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop"
import Homepage from "./Pages/Homepage";
import HomepageRO from "./Pages/HomepageRO";
import './default.scss';

function App() {

    return (
        <div className="App">
            <ScrollToTop />
            <Switch>
                <Route exact to path="/">
                    <Homepage />
                </Route>
                <Route to path="/ro">
                    <HomepageRO />
                </Route>
            </Switch>
        </div >
    );
}

export default App;
