import React, { useEffect, useState } from "react";

export default function Weather() {
  const [lat, setLat] = useState("44.297899");
  const [long, setLong] = useState("28.620062");
  const [weatherData, setWeatherData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      await fetch(
        `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${long}&units=metric&appid=7eab457c1f99ee881556969b2a09bfda`
      )
        .then((res) => res.json())
        .then((result) => {
          setWeatherData(result);
          // console.log(result);
        })
        .catch((error) => {
          // console.error("Error fetching weather data:", error);
        });
    };
    fetchData();
  }, []);

  const getWeatherType = (weatherId) => {
    // Weather condition codes: https://openweathermap.org/weather-conditions
    if (weatherId >= 200 && weatherId < 300) {
      return "Thunderstorm";
    } else if (weatherId >= 300 && weatherId < 400) {
      return "Drizzle";
    } else if (weatherId >= 500 && weatherId < 600) {
      return "Rain";
    } else if (weatherId >= 600 && weatherId < 700) {
      return "Snow";
    } else if (weatherId >= 700 && weatherId < 800) {
      return "Atmosphere";
    } else if (weatherId === 800) {
      return "Clear";
    } else if (weatherId > 800 && weatherId < 900) {
      return "Clouds";
    } else {
      return "Unknown";
    }
  };

  const getWeatherIcon = (weatherId) => {
    // Weather condition codes: https://openweathermap.org/weather-conditions
    if (weatherId >= 200 && weatherId < 300) {
      return "⛈️"; // Thunderstorm
    } else if (weatherId >= 300 && weatherId < 400) {
      return "🌧️"; // Drizzle
    } else if (weatherId >= 500 && weatherId < 600) {
      return "🌧️"; // Rain
    } else if (weatherId >= 600 && weatherId < 700) {
      return "❄️"; // Snow
    } else if (weatherId >= 700 && weatherId < 800) {
      return "🌫️"; // Atmosphere
    } else if (weatherId === 800) {
      return "☀️"; // Clear
    } else if (weatherId > 800 && weatherId < 900) {
      return "☁️"; // Clouds
    } else {
      return "❓"; // Unknown
    }
  };

  return (
    <div className="weather">
      {/* <h3>{data.main && Math.round(data.main.temp)}°C</h3> */}
      {weatherData && (
        <>
          <p className="weatherIcon">
            {getWeatherIcon(weatherData.weather[0].id)}
          </p>
          <h3>
            {Math.round(weatherData.main.temp)}°C
            <br />
            <small>{getWeatherType(weatherData.weather[0].id)}</small>
          </h3>
          <p></p>
          <p>Mamaia Nord</p>
        </>
      )}
    </div>
  );
}
